import React from 'react';
import Project from './Project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBriefcase, faCode } from '@fortawesome/free-solid-svg-icons';
import "../App.css"


const Portfolio = () => {
    const projects = [
        {
            title: 'Grocery Shopping Application',
            description: 'This is a grocery shopping application that allows users to order fresh groceries from home. The orders will be delivered at home. It has user friendly interface. Products are divided category wise for easy searching. The application contains a live tracking of orders. Firebase database is used for storing login data and data of all the orders.',
            technologies: ['Android Studio', 'Flutter','Firebase'],
            image: './images/bg.jpeg'
        },
        {
            title: 'TikTok Clone',
            description: 'This application is a replica of the real TikTok application.',
            technologies: ['Android Studio', 'Flutter','Firebase'] ,// Add any other relevant technologies]
            image: './images/bg.jpeg'
        },
        {
            title: 'All in One AI Application',
            description: 'An application and website which will contain multiple machine learning and NLP tools. After registration, user can get access to attendance management system, drowsiness detection system, mask detection system. All these are the AI tools implement after training the model on large dataset. ',
            technologies: ['Google Colab', 'Python','Machine Learning'], // Add any other relevant technologies],
            image: './images/bg.jpeg'
        },
    ];
    console.log(projects)
    return (
        <section className="about-section">
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}  >
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'center ', alignItems: 'center' }}>

                    <FontAwesomeIcon icon={faCode} className="section-icon" />
                    <h2 style={{ marginBottom: 30 }} className="section-heading">Projects</h2>
                </div>
                <div className="project-section" style={{ display: 'flex' }}>
                    {projects.map((project) => (
                        <Project key={project.title} {...project} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Portfolio;