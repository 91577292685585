import React, { useState } from 'react';
import Project from './Project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBriefcase, faCode } from '@fortawesome/free-solid-svg-icons';
import "../App.css"





const About = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleNext = () => setActiveIndex((prevIndex) => (prevIndex + 1) % 2);
    const handlePrev = () => setActiveIndex((prevIndex) => (prevIndex + 2 - 1) % 2);

    const projects = [
        {
            title: 'Grocery Shopping Application',
            description: 'This is a grocery shopping application that allows users to order fresh groceries from home. The orders will be delivered at home. It has user friendly interface. Products are divided category wise for easy searching. The application contains a live tracking of orders. Firebase database is used for storing login data and data of all the orders.',
            technologies: ['Android Studio', 'Flutter','Firebase']
        },
        {
            title: 'TikTok Clone',
            description: 'This application is a replica of the real TikTok application.',
            technologies: ['Android Studio', 'Flutter','Firebase'] // Add any other relevant technologies]
        },
        {
            title: 'All in One AI Application',
            description: 'An application and website which will contain multiple machine learning and NLP tools. After registration, user can get access to attendance management system, drowsiness detection system, mask detection system. All these are the AI tools implement after training the model on large dataset. ',
            technologies: ['Google Colab', 'Python','Machine Learning'] // Add any other relevant technologies],
        },
        {
            title: 'FPL Ene-FPLES Support',
            description: 'Providing reliable and cost-effective solutions, combined with personalized service and exceptional customer care.',
            technologies: ['C++'] // Add any other relevant technologies],
        }
    ];


    const education = [
        
        {
            institution: 'Maulana Abul Kalam Azad University of Technology',
            degree: 'Bachelor of Technology in Computer Science Engineering',
            location: 'West Bengal, India',
          //  duration: 'July 2016 - Sep 2020',
        },
       /* {
            institution: 'Northern Illinois University',
            degree: 'Master’s in Computer Science',
            location: 'DeKalb, IL',
            duration: 'August 2022 - May 2024',
        },*/
    ];

   const experience = [
        /*{
            company: 'Cognizant Technology Solutions',
            position: 'Quality Analyst',
            location: 'Coimbatore, India',
            duration: 'November 2020 - July 2022',
            highlights: [
                'Experience in automation and functional testing',
                'Knowledge of Selenium WebDriver, Agile methodology, Java, Oops Concepts, and SQL',
                'Analyzed Business requirements, Functional Requirements, designed Test Plan, created Test Requirements, Test Execution and Defect Management',
                'Interacted and coordinated with the Development team and Onsite team',
                'Handled testing for business critical applications and suggesting bugs of critical importance to the business and the corresponding applications',
                'Prepared test plan and test summary report',
            ],
        },*/
    ];

    return (
        <section className="about-section">
            <h1 className="about-heading">About Me</h1>
            <p className="about-intro">
                I don't just build software, I build solutions. My passion for tech translates to efficiency, reliability, and a seamless user experience.
            </p>

            <div className="carousel">
                <button style = {{fontWeight:'bold'}} onClick={handlePrev}>&lt;</button>
                <div className="carousel-inner">
                    <div className="about-sections">

                        <div className={`carousel-item ${0 === activeIndex ? 'active' : ''}`}>

                            <div className="about-section-item">
                                <FontAwesomeIcon icon={faGraduationCap} className="section-icon" />
                                <h2 className="section-heading">Education</h2>
                                <ul className="section-list">
                                    {education.map((educationItem) => (
                                        <li key={educationItem.institution}>
                                            <h3>{educationItem.degree}</h3>
                                            <p>{educationItem.institution}, {educationItem.location}</p>
                                            <p>{educationItem.duration}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>


                        <div className={`carousel-item ${1 === activeIndex ? 'active' : ''}`}>

                            <div className="about-section-item">
                                <FontAwesomeIcon icon={faBriefcase} className="section-icon" />
                                <h2 className="section-heading">Experience</h2>
                                <ul className="section-list">
                                    {experience.map((experienceItem) => (
                                        <li key={experienceItem.company}>
                                            <h3>{experienceItem.position}</h3>
                                            <p>{experienceItem.company}, {experienceItem.location}</p>
                                            <p>{experienceItem.duration}</p>
                                            <ul>
                                                {experienceItem.highlights.map((highlight) => (
                                                    <li key={highlight}>{highlight}</li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <button style = {{fontWeight:'bold'}} onClick={handleNext}>&gt;</button>
            </div>





            {/* Other about content, hobbies, interests, etc. */}
        </section>
    );
};

export default About;
