import React from 'react';
import '../App.css'; // Import your CSS file
import { Link } from 'react-router-dom';
import ProfileImage from "../images/profile1.png"

const Home = () => {
  return (
    <section style={{ flex: 1, height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3%', marginTop: '-16%' }} className="home">
      <div className="hero">
        <h1 className="heading">Shreni Singh</h1>
        <h2 className="subheading">Software Engineer</h2>
        <img src={ProfileImage} style={{ height: '20rem', width: '20rem'}} />
        <p className="intro">
          I'm a passionate software developer with experience in web development, automation testing, and quality assurance.
          I'm skilled in various technologies and always eager to learn new ones.
        </p>
        <Link className="button" to="/about">Learn More About Me</Link>
        <Link className="button" to="/portfolio">View My Projects</Link>
       
        

      </div>
    </section>
  );
};

export default Home;
